<template>
	<div>
		<Documents />
	</div>
</template>

<script>
import Documents from '@/components/templates/Documents.vue'
export default {
	components: {
		Documents
	}
}
</script>